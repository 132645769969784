
// Note the change from about-us to about_us.

export default {
  init() {
    // JavaScript to be fired on the home page

    //$('.entry-type-select .entry-input').selectpicker();

  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS



    const form = $('#search_news');

    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: form.serialize(), // serializes the form's elements.
      success: function (data) {
        $('.rb-listing-container').removeClass('loading');
        let result = jQuery.parseJSON(data);
        if (result.success) {
          $('#ajax_result').html(result.news_html);
          $('#ajax_pagination').html(result.pagination_html);
        } else {
          $('#ajax_result').html('<p>' + result.message + '</p>');
          $('#ajax_pagination').html('');
        }
      },
    });


    $('#search_news .entries select').each(function () {
      $(this).change(function () {
        if ($(this).val() !== '') {
          $('#search_news .btn__submit').attr('disabled', false);
        }
      });
    });



    $('#search_news').on('click', function () {
      if (form[0] && form[0]['page']) {
        form[0]['page'].value = 1;
      }
    })
    $('#search_news').submit(function (e) {
      e.preventDefault();
      $('.rb-listing-container').addClass('loading');
      $('.rb-listing').addClass('hidding');

      let form = $(this);
      let actionUrl = form.attr('action');
      window.history.pushState(null, null, '?' + form.serialize());

      $.ajax({
        type: 'POST',
        url: actionUrl,
        data: form.serialize(), // serializes the form's elements.
        success: function (data) {
          $('.rb-listing-container').removeClass('loading');
          $('.rb-listing').removeClass('hidding');
          let result = jQuery.parseJSON(data);
          if (result.success) {
            $('#ajax_result').html(result.news_html);
            $('.rb-filter .reset-filter').show();
            $('#ajax_pagination').html(result.pagination_html);
          } else {
            $('.rb-filter .reset-filter').show();
            $('#ajax_result').html('<p>' + result.message + '</p>');
            $('#ajax_pagination').html('');
          }
        },
      });
    });

    $('.rb-filter .reset-filter').click(function () {
      const form = $('#search_news');
      form[0].reset();
      $('select').niceSelect('update');


      $.ajax({
        type: 'POST',
        url: form.attr('action'),
        data: form.serialize(), // serializes the form's elements.
        success: function (data) {
          $('.rb-listing-container').removeClass('loading');
          let result = jQuery.parseJSON(data);
          if (result.success) {
            $('#ajax_result').html(result.news_html);
            $('#ajax_pagination').html(result.pagination_html);
            $('.rb-filter .reset-filter').hide();
          } else {
            $('#ajax_result').html('<p>' + result.message + '</p>');
            $('#ajax_pagination').html('');
          }
        },
      });
    })


    $(document).on('click', '#pagination_news a', function (e) {
      e.preventDefault();
      $('#search_news #page').val($(this).data('target'));
      $('#search_news').submit();
    });
  },
};
