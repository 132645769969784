import { gsap } from 'gsap';
class Header {

  constructor (params) {
    // Init variables
    this.$cache       = {};
    this.$cache.main  = params.target;
    this.isOpen = false;

    this.currentDkNav = false;
    this.dkIsOpen = false;
    this.mbIsOpen = false;
    this.mbSubIsOpen = false;


    this.initCache();
    this.initEventsConst();
    this.registerEvents();
    this.checkElem();

  }

  initCache(){
    this.$cache.html = $('html');
    this.$cache.body = this.$cache.html.find('body');
    this.$cache.header = this.$cache.html.find('header.header');
    this.$cache.navDk = this.$cache.header.find('.dk-nav');
    this.$cache.mid = this.$cache.navDk.find('.mega-menu');
    this.$cache.megaelement = this.$cache.mid.find('.mega-element');
    this.$cache.elemntslinks = this.$cache.megaelement.find('.meg-header');
    this.$cache.elemntslinksWrapper = this.$cache.elemntslinks.find('.meg-submenu');
    this.$cache.elemntslinksWithChildren = this.$cache.megaelement.find('.has_sub_menu');
    this.$cache.navMbElem = this.$cache.header.find('.smdcl-item.has_sub_menu a.btn');

    


    this.$cache.navDkCtas = this.$cache.megaelement.find('.meg-header[data-child]');
    this.$cache.fixedNavDkContainer = this.$cache.elemntslinksWrapper.find('.sub-elem-wrapper');
  }


  initEventsConst() {
    this.toggleNavDkEvent = (e) => { return this.openNavDk(e) };
    this.closeNavDkEvent = (e) => { return this.closeNavDk(e); };
    this.openTargetEvent = (e) => { return this.openTarget(e); };
    
    this.openElemWithChildren = (e) => { return this.openElem(e); };
    this.closeElemWithChildren = (e) => { return this.closeElem(e); };
    this.toggleNavMbEvent = (e) => { return this.toggleNavMb(e); };

  }


  registerEvents() {
    this.$cache.megaelement.on('mouseenter', this.toggleNavDkEvent);
    this.$cache.megaelement.on('click', this.openTargetEvent);
    this.$cache.megaelement.on('mouseleave', this.closeNavDkEvent);
    this.$cache.elemntslinksWithChildren.on('mouseenter', this.openElemWithChildren);
    this.$cache.elemntslinksWithChildren.on('mouseleave', this.closeElemWithChildren);
    this.$cache.navMbElem.on('click', this.toggleNavMbEvent);

  }


  checkElem() {
       $('.smdcl-item.has_sub_menu').on('click',(e)=> {
            if(window.innerWidth < 1100) {
                e.preventDefault();
            }

       })
  }

  
  openTarget (e) {
    let $el = $(e.currentTarget).find('.meg-header');
    if( $el && $el.data('url') && $el.data('url').length > 0) window.location.href = $el.data('url');
  }

  openElem (e) {
    let $el = $(e.currentTarget);
    let targetChildren = $el.find('.smdc-submenu');
    targetChildren.addClass('is-active');
    $el.addClass('is-active');


    let tl = new gsap.timeline({paused: true});
    tl.add(function () {
      targetChildren.addClass('is-open');
      $el.addClass('is-open');
    }, 0);
    if(targetChildren) {
      tl.play();
    }
  }


  closeElem(e) {
    let $el = $(e.currentTarget);
    let targetChildren = $el.find('.smdc-submenu');
    targetChildren.removeClass('is-active');
    $el.removeClass('is-active');


    let tl = new gsap.timeline({paused: true});
    tl.add(function () {
      targetChildren.removeClass('is-open');
      $el.removeClass('is-open');
    }, 0);
    if(targetChildren) {
      tl.play();
    }
  }




  closeNavDk(e) {
   // e.preventDefault();

    let $el = $(e.currentTarget);


    this.dkIsOpen = false;

    let targetWrap = $el.find('.sub-elem-wrapper');
    let targetContainer = $el.find('.submenu-card');
    let targetBtn = $el.find('.sc-bottom .btn');
    let targetLeftBlockBg = $el.find('.sg-right-bloc__bg');
    let targetLeftBlockContent = $el.find('.sg-right-bloc__content');
    let targeIsGridType = $el.find('.sb-grid');

    let tl = new gsap.timeline({paused: true,
      onComplete:  () => {
        this.$cache.megaelement.removeClass('is-active');
        $el.removeClass('is-active');
      },

    });
    tl.add(function () {
      targetContainer.removeClass('is-open');
      $el.removeClass('is-open');
    }, 0);
    if(targeIsGridType && targeIsGridType.length > 0) {
     
      tl.fromTo(targetWrap, 0.3, {
        height: 'auto',
      }, {
        height:0,
        ease: 'Expo.easeOut',
      }, 0.5);
      tl.fromTo(targetContainer, 0.52, {
        opacity:1,
      }, {
        opacity: 0,
        ease: 'Expo.easeOut',
      }, 0.2);

      if(targetBtn) {
        tl.fromTo(targetBtn, 0.5, {
          opacity: 1,
          x: '0',
        }, {
          opacity:0,
          x: '-50%',
          ease: 'Power2.easeOut',
        }, 0.5);
      }
      if(targetLeftBlockBg) {
        tl.fromTo(targetLeftBlockBg, 0.5, {
          opacity: 1,
          width:'100%',
        }, {
          transformOrigin: '100% 50%',
          opacity:0,
          width:0,
          ease: 'Power2.easeOut',
        }, 0.2);
      }
      if(targetLeftBlockContent) {
        tl.fromTo(targetLeftBlockContent, 0.3, {
          opacity: 1,
          y: '0',
        }, {
          opacity:0,
          y: '30%',
          ease: 'Power2.easeOut',
        }, 0);
      }
    }


    tl.play();
  }



  openNavDk (e) {
    //e.preventDefault();

    let $el = $(e.currentTarget);
    this.$cache.megaelement.removeClass('is-active');
    $el.addClass('is-active');

    if (this.dkIsOpen) { return false; }
    this.dkIsOpen = true;

    let targetWrap = $el.find('.sub-elem-wrapper');
    let targetContainer = $el.find('.submenu-card');
    let targetBtn = $el.find('.sc-bottom .btn');
    let targetLeftBlockBg = $el.find('.sg-right-bloc__bg');
    let targetLeftBlockContent = $el.find('.sg-right-bloc__content');

    let targeIsGridType = $el.find('.sb-grid');




    let tl = new gsap.timeline({paused: true});
    tl.add(function () {
      targetContainer.addClass('is-open');
      $el.addClass('is-open');
    }, 0);

    if(targeIsGridType && targeIsGridType.length > 0) {
      tl.fromTo(targetWrap, 0.7, {
        height:0,
      }, {
        height: 'auto',
        ease: 'Expo.easeOut',
      }, 0);
      tl.fromTo(targetContainer, 0.5, {
        opacity:0,
      }, {
        opacity: 1,
        ease: 'Expo.easeOut',
      }, 0.2);

      if(targetBtn) {
        tl.fromTo(targetBtn, 0.5, {
          opacity:0,
          x: '-50%',
        }, {
          opacity: 1,
          x: '0',
          ease: 'Power2.easeOut',
        }, 0.5);
      }
      if(targetLeftBlockBg) {
        tl.fromTo(targetLeftBlockBg, 0.5, {
          opacity:0,
          width:0,
        }, {
          transformOrigin: '100% 50%',
          opacity: 1,
          width:'100%',
          ease: 'Power2.easeOut',
        }, 0.7);
      }
      if(targetLeftBlockContent) {
        tl.fromTo(targetLeftBlockContent, 0.3, {
          opacity:0,
          y: '30%',
        }, {
          opacity: 1,
          y: '0',
          ease: 'Power2.easeOut',
        }, 1.2);
      }
    }


    tl.play();
  }


  toggleNavMb (e) {
    let $el = $(e.currentTarget);
    $el.parents('.smdcl-item').toggleClass('is-open');
  }

}

export default Header;
