class YoutubeApi {

    constructor (params) {
        // Init variables
        this.$cache       = {};
        this.$cache.main  = params.target;

        this.playerId  = false;
        this.vidId     = false;
        this.isInit    = false;
        this.playerObj = false;
        this.isReady = false;
        this.stateChangeCallbacks = {
                                        notStarted : false,
                                        end : false,
                                        play : false,
                                        pause : false,
                                        buffer : false,
                                        queue : false,
                                    };

        // Trigger init functions
        this.initCache();
        this.init();
    }

    initCache() {
        this.$cache.iframe = this.$cache.main.find('.iframe-placeholder');
    }

    init() {
          //Init Youtube API
        this.initApi();

        //Init variables
        this.playerId = this.$cache.iframe.attr('id');
        this.vidId = this.$cache.main.data('id');

        //If API is ready
        if(window.youtubeApiReady !== undefined && window.youtubeApiReady == true) {
            this.initPlayer();
        }
    }

      /**
     * Init Youtube API
     */
    initApi() {
        if(window.youtubeApiReady !== undefined) return;
        window.youtubeApiReady = false;

        // Insert Api
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        //Callback function
        window['onYouTubeIframeAPIReady'] = function(){
            // console.log('YT Api Ready');
            window.youtubeApiReady = true;

            //Trigger init for all component after Api callback
            let $components = $('[data-component=\'youtubeApi\']');
            $components.each((index, element) => {
                let $el = $(element);
                let compObj = $el.data('comp');

                if(typeof compObj.initPlayer === 'function') compObj.initPlayer();
            });
        };
    }

      /**
     * Init Player
     */
    initPlayer() {
        if(this.isInit) return;
        this.isInit = true;

        /* eslint-disable no-undef */
        this.playerObj = new YT.Player(this.playerId, {
                                                videoId: this.vidId,
                                                playerVars: {rel: 0},
                                                events: {
                                                            'onReady': () => this.onPlayerReady(),
                                                            'onStateChange': (e) => this.onPlayerStateChange(e),
                                                            'onError': (e) => this.onPlayerError(e),
                                                        },
                                            });
        /* eslint-enable no-undef */
    }

    /**
     * On Player Ready
     */
    onPlayerReady() {
        this.isReady = true;
        this.$cache.main.trigger('ready');
    }

    /**
     * On Player Error
     */
    onPlayerError(e) {
        console.log('Player Youtube Error : '+e.data);
    }

    /**
     * On Player State Change
     */
    onPlayerStateChange(e) {
        switch (e.data) {
            case -1:
                //Video not started
                //console.log('Video not started');
                if(this.stateChangeCallbacks.notStarted && typeof this.stateChangeCallbacks.notStarted === 'function') this.stateChangeCallbacks.notStarted();
                break;
            case 0:
                //Video End
                //console.log('Video End');
                this.$cache.main.trigger('ended');
                if(this.stateChangeCallbacks.end && typeof this.stateChangeCallbacks.end === 'function') this.stateChangeCallbacks.end();
                break;
            case 1:
                //Video play
                //console.log('Video play');
                if(this.stateChangeCallbacks.play && typeof this.stateChangeCallbacks.play === 'function') this.stateChangeCallbacks.play();
                break;
            case 2:
                //Video pause
                //console.log('Video pause');
                if(this.stateChangeCallbacks.pause && typeof this.stateChangeCallbacks.pause === 'function') this.stateChangeCallbacks.pause();
                break;
            case 3:
                //Video buffer
                //console.log('Video buffer');
                if(this.stateChangeCallbacks.buffer && typeof this.stateChangeCallbacks.buffer === 'function') this.stateChangeCallbacks.buffer();
                break;
            case 5:
                //Video in queue
                //console.log('Video in queue');
                if(this.stateChangeCallbacks.queue && typeof this.stateChangeCallbacks.queue === 'function') this.stateChangeCallbacks.queue();
        }
    }

    /**
     * Play Video
     */
    playVideo(callback) {
        if(!this.playerObj) return;

        this.playerObj.playVideo();
        //console.log('play');

        if(typeof callback === 'function') callback();
    }

    /**
     * Pause Video
     */
    pauseVideo(callback) {
        if(!this.playerObj) return;

        this.playerObj.pauseVideo();

        if(typeof callback === 'function') callback();
    }

    /**
     * Stop Video
     */
    stopVideo(callback) {
        if(!this.playerObj) return;

        this.playerObj.stopVideo();

        if(typeof callback === 'function') callback();
    }

    /**
     * Seek Video Time
     */
    seekVideo(seconds, callback) {
        if(!this.playerObj) return;

        this.playerObj.seekTo(seconds);

        if(typeof callback === 'function') callback();
    }

    /**
     * End Video
     */
    endVideo(callback) {
        if(!this.playerObj) return;

        this.playerObj.seekTo(this.playerObj.getDuration());

        if(typeof callback === 'function') callback();
    }

    /**
     * Load new Video
     */
    loadNew(id, callback) {
        if(!this.playerObj) return;

        this.playerObj.loadVideoById(id);

        if(typeof callback === 'function') callback();
    }
}

export default YoutubeApi;
