
// Note the change from about-us to about_us.

export default {
  init() {
    // JavaScript to be fired on the home page

    //$('.entry-type-select .entry-input').selectpicker();

  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    function detectCheckboxes() {

      //Check if register form is available - loaded by Ultimate Member plugin
      let form = document.querySelector('.um-form');

      if (!form) {
        setTimeout(detectCheckboxes, 300);
      }
      //get All Custom checkboxes - Ultimate Member - bug when checkbox is already active when reloading form after validation
      let checkboxes = document.querySelectorAll('.um-field.um-field-checkbox');
      if (checkboxes.length>0) {

        checkboxes.forEach(function(checkbox) {          
          var inputCheckbox = checkbox.querySelector('input[type="checkbox"]');
          var inputLabel = checkbox.querySelector('label.um-field-checkbox');
          
          //add classes to related elements if checkbox is checked
          if (inputCheckbox.checked) {
            if (!inputLabel.classList.contains('active')) {
              inputLabel.classList.add('active');
            }
            //wait for .customCheckbox div not created in DOM - seems initialized by Ultimate Member plugin JS
            setTimeout(() => {
              var customCheckbox = inputLabel.querySelector('.customCheckbox');
              if (!customCheckbox.classList.contains('.customCheckboxChecked')) {
                customCheckbox.classList.add('customCheckboxChecked');
              }
            }, 500);
          }
        });
      }
    }

    detectCheckboxes();

  },
};
