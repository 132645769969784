import { gsap } from 'gsap';
class Ecosystem {
  constructor (params) {
    // Init variables
    this.$cache       = {};
    this.$cache.main  = params.target;
    this.initCache();
    this.initEventsConst();
    this.registerEvents();
  }
  initCache() {
    this.$cache.html = $('html');
    this.$cache.body = this.$cache.html.find('body');
    this.$cache.header = this.$cache.html.find('header.header');
    this.$cache.elem = this.$cache.main.find('.interact');
  }
  initEventsConst() {
    this.showEcoBlockEvent = (e) => { return this.showEcoBlock(e) };
  }
  registerEvents() {
    this.$cache.elem.on('click', this.showEcoBlockEvent);
    this.$cache.elem.on('mouseover', this.showEcoBlockEvent);
  }
  showEcoBlock(e) {
    let $el = $(e.currentTarget);
    let blockTargetData = $el.data('target');
    if( blockTargetData) {
        let blockTarget = this.$cache.main.find(`#${blockTargetData}`);
        if(blockTarget) {
                let currentBlock = this.$cache.main.find('.is-active');
                if(currentBlock && !blockTarget.is(currentBlock)) {
                    this.animationBlock($el, currentBlock, 'out');
                    this.animationBlock($el, blockTarget, 'in');
                }
        }
    }
  }
  animationBlock($source, $el , style) {
    let isInStyle = style === 'in' ;
    if(isInStyle){
      $el.addClass('is-active')
      $('#Calque_2 g').removeClass('active')
      $source.addClass('active')
    }
    let tl = new gsap.timeline({paused: true,
        onComplete:  () => {
            if(!isInStyle){
              $el.removeClass('is-active')
            }
          },
        });
      tl.fromTo($el, 0.5,
      {
        autoAlpha: isInStyle ? 0 : 0.3 ,
        y:  isInStyle ? '-50px' : '0' ,
      }, {
        autoAlpha: isInStyle ? 1 : 0 ,
        y:  isInStyle ? 0 : '-50px' ,
        ease: 'Expo.easeOut',
      },  isInStyle ? 1 : 0.3);
      tl.play();
  }
}
export default Ecosystem;