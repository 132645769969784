// import external dependencies
import 'jquery';
import IMask from 'imask'

// Import everything from autoload
import './autoload/_bootstrap.js'

import './components/jquery.nice-select';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import templateResourceIndex from './routes/templateResourceIndex';
import templateNewsIndex from './routes/templateNewsIndex';
import templateUmRegister from './routes/templateUmRegister';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Guide et ressources page, note the change from about-us to aboutUs.
  templateResourceIndex,
  // blog page .
  templateNewsIndex,
  // register page
  templateUmRegister,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
var queue = [];

function maskingInputs() {

  const birthdayInput = document.querySelector('input[name=birthday]');
  if (birthdayInput) {
    IMask(birthdayInput, {
      mask: Date,  // enable date mask

      // other options are optional
      pattern: 'd/`m/`Y',  // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
      // you can provide your own blocks definitions, default blocks for date mask are:
      blocks: {
        d: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        Y: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 2030,
          minLength: 4,
          maxLength: 4,
        },
      },
      // define date -> str convertion
      format: date => {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;

        return [day, month, year].join('/');
      },

      // define str -> date convertion
      parse: str => {
        const yearMonthDay = str.split('/');
        return new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0]);
      },

      placeholderChar: '/',
      autofix: false,  // defaults to `false`

      // pattern options can be set as well
      //lazy: false,

      // and other common options
      overwrite: true,  // defaults to `false`
    });
  }



  const phoneInput = document.querySelector('input[name=phone_number]');
  const phoneInputPhar = document.querySelector('input[name=pharmacien_phone]');
  const phoneInputContact = document.querySelector('input[name=contact_phone_number]');
  const phoneInputPrinciple = document.querySelector('input[name=phone]');

  const maskOptions = {
    mask: '000-000-0000',
    mask2: '(000) 000-0000',
  };
  if (phoneInput) IMask(phoneInput, maskOptions);
  if (phoneInputPhar) IMask(phoneInputPhar, maskOptions);
  if (phoneInputContact) IMask(phoneInputContact, maskOptions);
  if (phoneInputPrinciple) IMask(phoneInputPrinciple, maskOptions);
}


function menuSticky() {
  if (scrollY > 200) {
    $('.header .header-nav').addClass('is-sticky');
  } else $('.header .header-nav').removeClass('is-sticky');
}


$(document).on('scroll', function () {

  menuSticky();
});

let reference_form = document.getElementById('reference_form');
if (reference_form) {
  reference_form.addEventListener('submit', function (e) {
    e.preventDefault();
    $('.entry-default').each(function () {
      $(this).removeClass('error');
    });
    $('.file-upload-contain').each(function () {
      $(this).removeClass('error');
    });
    $('.form-load').show();
    let form = $(this);
    let formData = new FormData(this);
    for (let i = 0; i < queue.length; i++) {
      if (queue[i]) {
        formData.append('documents[]', queue[i].data, queue[i].name);
      }
    }
    let actionUrl = form.attr('action');
    $.ajax({
      type: 'POST',
      url: actionUrl,
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        $('.form-load').hide();
        if (data.success) {
          $('#form').css('display', 'none');
          $('#form-message').css('display', 'block');
          $('html').animate(
            {
              scrollTop: $('#form-message').offset().top - 200,
            },
            800 
          );
        } else {
          $.each(data.error, function (key) {
            $('#' + key).parents('.entry-default').children('.invalid-feedback').text(data.error[key]);
            $('#' + key).parents('.entry-default').addClass('error');
            $('#' + key).parent().addClass('error');
          });
        }
      },
    });
  });
}

$(document).ready(function () {

  menuSticky();
  $('#form-message').css('display', 'none');
  $('#success-message').css('display', 'none');
  $('.success').css('display', 'none');

  maskingInputs();

  let fromRef = $('#reference_form');

  if (fromRef) {


    $('input[type="radio"]').click(function () {
      var inputValue = $(this).attr('value');
      if (inputValue === 'ramq') {
        $('.hidden-row_assurance').show();
        $('.rmaq_input').show();
        $('.private_input').hide();
        $(this).parents('.customCheckbox').addClass('customCheckboxChecked');
        $('#private').parents('.customCheckbox').removeClass('customCheckboxChecked');

      } else if (inputValue === 'private') {
        $('.hidden-row_assurance').show();
        $('.private_input').show();
        $('.rmaq_input').hide();
        $(this).parents('.customCheckbox').addClass('customCheckboxChecked');
        $('#rmq').parents('.customCheckbox').removeClass('customCheckboxChecked');
      }

    });

  }


  $('.form_newsletter').submit(function (e) {
    e.preventDefault();
    $('.entry-default').each(function () {
      $(this).removeClass('error');
    });
    let form = $(this);
    let formData = form.serialize();
    let actionUrl = form.attr('action');
    $.ajax({
      type: 'POST',
      url: actionUrl,
      data: formData,
      processData: false,

      success: function (data) {
        if (data.success) {
          $('.success').css('display', 'block');
          setTimeout(function () {
            location.reload();
          }, 4000); // 4000 milliseconds = 4 seconds
        } else {
          $.each(data.error, function (key) {
            $('#' + key).parents('.entry-default').children('.invalid-feedback').text(data.error[key]);
            $('#' + key).parents('.entry-default').addClass('error');
            $('#' + key).parent().addClass('error');
          });
        }
      },
    });
  });

  $('#contact').submit(function (e) {
    e.preventDefault();
    $('.entry-default').each(function () {
      $(this).removeClass('error');
    });
    let form = $(this);
    let actionUrl = form.attr('action');
    $('.form-load').show();
    $.ajax({
      type: 'POST',
      url: actionUrl,
      data: form.serialize(),
      success: function (data) {
        $('.form-load').hide();
        if (data.success) {
          $('#contact').hide();
          $('#success-message').css('display', 'block');
          $('#contact').trigger('reset');
        } else {
          $.each(data.error, function (key) {
            if (key == 'cgv') {
              $('#' + key).parents('.entry-default').addClass('error');
            } else {
              $('#' + key).parent().addClass('error');
            }

          });
        }
      },
    });
  });


  $('.mega-element').click(function () {
    var elemnt = $(this).find('div.meg-header');
    if (!elemnt.hasClass('has_sub_menu')) {
      window.location.href = elemnt.data('url');
    }
  });

  // Check if menu width is inferior to window


  function checkMenuWidth(elem) {
    const leftOffset = $(elem).offset().left;
    const width = $(elem).width();

    const lw = leftOffset + width;

    if (lw > innerWidth) $(elem).addClass('right');



  }
  $('.mega-element').on('mouseover', function () {
    checkMenuWidth($(this).children('.meg-submenu'));
  });

  $('.mega-element .smdcl-item.has_sub_menu').on('mouseover', function () {
    checkMenuWidth($(this).children('.smdc-submenu'));
  });


  /* begin menu*/
  /* $('.meg-header').hover(
    function() {
      $('.meg-submenu').hide();
      $('.smdc-submenu').hide();
      var target_submenu = $(this).data('child');
      $('#'+target_submenu).show();
    }
  );

  $('.smdcl-item ').hover(
    function() {
      $('.smdc-submenu').hide();
      var target_submenu = $(this).data('child');
      $('#'+target_submenu).show();
    }
  );

  /* end menu*/

  /* begin menu mobile*/
  $('#open-mb-menu').click(function (e) {
    e.preventDefault();
    $('#mb-menu-container').show();
  })
  $('#close-menu-mb').click(function (e) {
    e.preventDefault();
    $('#mb-menu-container').hide();
  })


  $('.mb-meg-header').click(function () {
    if ($(this).hasClass('has_sub_menu')) {
      var submenu_id = $(this).data('child');
      $('#' + submenu_id).show();
      $('.mb-meg-header').hide();
    } else {
      window.location.href = $(this).data('url');
    }
  })

  $('.close-submenu').click(function (e) {
    e.preventDefault();
    $('.mb-meg-submenu').hide();
    $('.mb-meg-header').show();
  })
  /* end menu mobile*/

  /* Input file */
  function addFile(parent, file, i) {
    let spanfn = document.createElement('span');
    parent.appendChild(spanfn);
    spanfn.className = 'filename';

    let spanimm = document.createElement('span');
    spanfn.appendChild(spanimm);
    spanimm.innerHTML = file.name;

    let spanrm = document.createElement('i');
    spanfn.appendChild(spanrm);
    spanrm.className = 'remove';

    spanrm.addEventListener('click', function () {
      spanfn.remove();
      delete (queue[i])
    })
    // $('.file-elements').append('<span class=\'filename\'><span>' + f.name +
    //   '</span><i class=\'remove\'></i></span>');
    // $('.remove').on('click', function () {
    //   $(this).parents('.filename').remove();
    // });
  }
  function addBlob(file) {
    addFile($('.file-elements')[0], file, queue.length)
    queue.push(
      { data: new Blob([file], { type: file.type }), name: file.name, type: file.type }
    );
    // let reader = new FileReader();
    // reader.onloadend = function () {

    // };
    // reader.readAsBinaryString(file);
  }
  $('#multiplefileupload').on('change', function (e) {
    var files = e.target.files,
      filesLength = files.length;
    for (var i = 0; i < filesLength; i++) {
      addBlob(files[i])
    }
  });

  /* Collapse */

  $('.collapse-header').on('click', function () {
    var content = $(this).next();
    content.slideToggle(500, function () {
      $('.arrow_title').toggleClass('rotate');
    });
  });

  /* Select initial */
  $('select:not(.ignore)').niceSelect();

  /* Checkbox */
  $(function () {
    var checkboxs = $('input[type=checkbox]');
    var radios = $('input[type=radio]');

    checkboxs.each(function () {
      $(this).wrap('<div class="customCheckbox"></div>');
      $(this).before('<span class=\'checkIcon\'></span>');
    });
    checkboxs.change(function () {
      if ($(this).is(':checked')) {
        $(this).parent().addClass('customCheckboxChecked');
      } else {
        $(this).parent().removeClass('customCheckboxChecked');
      }
    });

    radios.each(function () {
      $(this).wrap('<div class="customCheckbox"></div>');
      $(this).before('<span class=\'checkIcon\'></span>');
    });



  })


  /* Visual */
  // $('#Calque_2 g').on('click', function () {
  //   $(this).addClass('active');
  //   $(this).siblings('g').removeClass('active');
  // });

  var arrow_left = '<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.8882 15.8877L9.214 14.5619L3.58917 8.93706L20 8.93706L20 7.06199L3.58934 7.06199L9.214 1.43733L7.8882 0.111532L-6.89598e-07 7.99961L7.8882 15.8877Z" fill="#fff"/></svg>';
  var arrow_right = '<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.1118 0.112305L10.786 1.43811L16.4108 7.06294L0 7.06294L0 8.93801L16.4107 8.93801L10.786 14.5627L12.1118 15.8885L20 8.00039L12.1118 0.112305Z" fill="white"/></svg>';


  $('.owl-slider-posts').owlCarousel({
    loop:false,
    margin:20,
    nav:true,
    dot:false,
    navText: ['<span class="icon">'+arrow_left+'</span>', '<span class="icon">'+arrow_right+'</span>'],
    responsive:{
      0:{
        items:1,
      },
      600:{
        items:2,
      },
      1000:{
        items:3,
      },
    },
  });

  
  $('.owl-media').owlCarousel({
    loop:false,
    nav:true,
    center: true,
    dot:true,
    navText: ['<span class="icon">'+arrow_left+'</span>', '<span class="icon">'+arrow_right+'</span>'],
    items:1,
  });

  var popupCenter = function(url, title, width, height){
    var popupWidth = width || 640;
    var popupHeight = height || 320;
    var windowLeft = window.screenLeft || window.screenX;
    var windowTop = window.screenTop || window.screenY;
    var windowWidth = window.innerWidth || document.documentElement.clientWidth;
    var windowHeight = window.innerHeight || document.documentElement.clientHeight;
    var popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2 ;
    var popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
    var popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
    popup.focus();
    return true;
  };

  let facebook = document.querySelector('.sharing-area .share_facebook');
    if (facebook !== null) {
        document.querySelector('.sharing-area .share_facebook').addEventListener('click', function(e){
        e.preventDefault();
        var url = this.getAttribute('href');
        var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
        popupCenter(shareUrl, 'Partager sur facebook');
    });
  }

  let linkedin = document.querySelector('.sharing-area .share_linkedine');
    if (linkedin !== null) {
        document.querySelector('.sharing-area .share_linkedine').addEventListener('click', function(e){
          e.preventDefault();
          var url = this.getAttribute('href');
          var shareUrl = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(url) ;
          popupCenter(shareUrl, 'Partager sur LinkedIn');
      });
  }

});

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.custom-play-button').forEach(function(button) {
      button.addEventListener('click', function() {
          var videoContainer = button.closest('.videoContent');
          var thumbnail = videoContainer.querySelector('.video-thumbnail');
          var iframe = videoContainer.querySelector('iframe');

          thumbnail.style.display = 'none'; // Hide the thumbnail
          iframe.style.display = 'block'; // Show the iframe

          // Set iframe src with autoplay parameter
          var src = iframe.getAttribute('src');
          iframe.setAttribute('src');
      });
  });
});


document.addEventListener('DOMContentLoaded', function() {
  var menuTrigger = document.getElementById('menu-trigger');
  var menu = document.getElementById('menu-espace-pro');

  if (menuTrigger) {
    // Show menu on hover
    menuTrigger.addEventListener('mouseenter', function() {
        menu.style.display = 'block';
    });

    // Hide menu when mouse leaves the container
    menuTrigger.addEventListener('mouseleave', function() {
        setTimeout(function() {
            if (!menu.matches(':hover')) {
                menu.style.display = 'none';
            }
        }, 100); // Small delay to allow mouse to move to menu
    });

    // Show menu when mouse enters the menu
    menu.addEventListener('mouseenter', function() {
        menu.style.display = 'block';
    });

    // Hide menu when mouse leaves the menu
    menu.addEventListener('mouseleave', function() {
        menu.style.display = 'none';
    });

    // Hide menu when clicking outside
    document.addEventListener('click', function(event) {
        if (!menuTrigger.contains(event.target) && !menu.contains(event.target)) {
            menu.style.display = 'none';
        }
    });
  }

  
});

// Fonction Load more newsletter list

document.addEventListener('DOMContentLoaded', function() {
  const itemsToShow = 6; // Number of items to show initially
  const loadMoreCount = 3; // Number of items to show more on each click

  const $container = document.querySelector('.block-exintlinks__list');
  if (!$container) return; // Exit if the container doesn't exist

  const $items = Array.from($container.querySelectorAll('.exintlinks'));
  if ($items.length === 0) return; // Exit if there are no items

  const $loadMoreButton = document.querySelector('.link-more-newsletter a');
  const $loadMoreButtonContent = document.querySelector('.link-more-newsletter');

  const desktopMediaQuery = window.matchMedia('(min-width: 768px)');

  function handleResize() {
    if (desktopMediaQuery.matches) {
      // Apply "Load more" functionality only for desktop
      // Ensure items are initially hidden and then shown
      $items.forEach(item => item.style.display = 'none');
      $items.slice(0, itemsToShow).forEach(item => item.style.display = 'flex');

      // Show or hide "Load more" button based on the number of items
      if ($loadMoreButtonContent) {
        if ($items.length > itemsToShow) {
          $loadMoreButtonContent.style.display = 'flex'; // Use block to ensure proper display
        } else {
          $loadMoreButtonContent.style.display = 'none';
        }
      }
      if ($loadMoreButton) {
        // Remove previous event listeners to avoid multiple bindings
        $loadMoreButton.removeEventListener('click', loadMoreHandler);

        // Add new event listener
        $loadMoreButton.addEventListener('click', loadMoreHandler);
      }

    } else {
      // Remove the "Load more" button and functionality on mobile
      if ($loadMoreButton) {
        $loadMoreButtonContent.style.display = 'none';
      }

      // Ensure all items are visible on mobile
      $items.forEach(item => item.style.display = 'flex');
    }
  }

  // Handle loading more items
  function loadMoreHandler(e) {
    e.preventDefault();

    // Show the next set of items
    const hiddenItems = $items.filter(item => item.style.display === 'none');
    const nextItems = hiddenItems.slice(0, loadMoreCount);

    nextItems.forEach(item => item.style.display = 'flex');

    // Hide the button if all items are shown
    if ($container.querySelectorAll('.exintlinks[style*="display: flex"]').length >= $items.length) {
      $loadMoreButtonContent.style.display = 'none';
    }
  }

  // Initial check on page load
  handleResize();

  // Check on window resize
  desktopMediaQuery.addEventListener('change', handleResize);
});
