/* stylelint-disable */
//import { gsap } from 'gsap';
class anchorBlock {

    constructor (params) {
        // Init variables
        this.$cache       = {};
        this.$cache.main  = params.target;
    
    
        this.initCache();
       // this.initEventsConst();
       // this.registerEvents();
    
    }


    initCache () {

        this.$cache.html = $('html');
        this.$cache.body = this.$cache.html.find('body');

        this.$cache.anchor = this.$cache.main.find('.anchor-link');

        if(this.$cache.anchor.length >0  ) {
            if(this.$cache.anchor.attr('href').match('^#')) $(this.$cache.anchor).addClass('down');
          
        }
      

    }







}

export default anchorBlock;