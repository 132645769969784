class VideoHTML {
    constructor (params) {
        // Init variables
        this.$cache       = {};
        this.$cache.main  = params.target;

        this.isMuted    = false;
        this.isLoop     = false;
        this.isAutoPlay = false;
        this.isPlaying  = false;
		
		this.srcDK = false;
		this.srcMb  = false;

        // Trigger init functions
        this.initCache();
        this.initEventConst();
        this.init();
    }

    initCache() {
        this.$cache.html = $('html');
        this.$cache.video = this.$cache.main.find('video');
        this.$cache.videoO = this.$cache.video[0];
    }

    init() {
        let muted = this.$cache.video.prop('muted');
        let loop = this.$cache.video.prop('loop');
        let autoplay = this.$cache.video.prop('autoplay');

        if(typeof muted !== undefined && muted !== false) this.isMuted = true;
        if(typeof loop !== undefined && loop !== false) this.isLoop = true;
        if(typeof autoplay !== undefined && autoplay !== false) this.isAutoPlay = true;
		
		this.srcDK = this.$cache.video.data('src');
		this.srcMb  = this.$cache.video.data('src-mobile');
		
		if(this.srcMb === undefined) {
			this.$cache.video.attr('src', this.srcDK);
		} else {
			this.setSrc();
		}
		
        this.registerEvents();
    }

    initEventConst() {
        this.onPlayEvent = () => this.onPlay();
        this.onPauseEvent = () => this.onPause();
    }

    registerEvents() {
        this.$cache.video.on('loadeddata', () => {
            this.$cache.main.trigger('loaded');
			
			//Play video if already playing
			if(this.isPlaying) this.play();
        });

        this.$cache.video.on('play', this.onPlayEvent);
        this.$cache.video.on('pause', this.onPauseEvent);
    }

    play() {
        this.$cache.videoO.play();
    }

    pause () {
        this.$cache.videoO.pause();
    }

    stop() {
        this.$cache.videoO.pause();
        this.$cache.videoO.currentTime = 0;
    }

    onPlay() {
        this.isPlaying = true;
    }

    onPause() {
        this.isPlaying = false;
    }

    soundToggle() {
        if( this.isMuted ) {
            this.$cache.video.prop('muted', false);
            this.isMuted = false;
        } else {
            this.$cache.video.prop('muted', true);
            this.isMuted = true;
        }
    }
	
	setSrc() {
		if ((window.bp.value === 'xs' || window.bp.value === 'sm')) {
			this.$cache.video.attr('src', this.srcMb);
		} else {
			this.$cache.video.attr('src', this.srcDK);
		}
	}
	
	resize() {
		if (window.bp.lastValue !== window.bp.value) {
			if ((window.bp.value === 'xs' || window.bp.value === 'sm') && (window.bp.lastValue === 'md' || window.bp.lastValue === 'lg' || window.bp.lastValue === 'xl')) {
				// Desktop -> Mobile
				if(this.srcMb !== undefined) {
					this.setSrc();
				}
			} else if ((window.bp.value === 'md' || window.bp.value === 'lg' || window.bp.value === 'xl') && (window.bp.lastValue === 'xs' || window.bp.lastValue === 'sm')) {
				// Mobile -> Desktop
				if(this.srcMb !== undefined) {
					this.setSrc();
				}
			}
		}
	}
}
export default VideoHTML;
