export default {
  init() {
    // JavaScript to be fired on the home page
    $(document).ready(function() {
      // Function to handle the click event and navigate to the data-url
      function handleClick(event) {
        const url = event.currentTarget.getAttribute('data-url');
        if (url) {
          window.location.href = url;
        }
      }
      // Add event listeners to all divs with the class "clickable"
      document.querySelectorAll('.has_link').forEach(div => {
        div.addEventListener('click', handleClick);
      });
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
