//Globals
import env from '../libs/env';
import Breakpoint from '../libs/breakpoint';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';

//Modules
import  'jquery.marquee';
import 'owl.carousel';

import Components from '../modules/components';

export default {

  finalize: function () {
    // JavaScript to be fired on all pages, after page specific JS is fired
    const accordion = document.getElementsByClassName('accordion');

    for (let i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener('click', function (event) {
        //detect link in accordion element
        if (event.target.parentNode.nodeName=='a' || event.target.parentNode.classList.contains('accordion__link') || event.target.nodeName=='a') {
          return;
        }
        $(this).parent().siblings().children('.accordion').removeClass('active');
        $(this).parent().siblings().children('.accordion').children('.accordion__description').css('height', 0);
        if(this.classList.contains('active')) {
          $(this).removeClass('active')
          this.querySelector('.accordion__description').style.height =  0;
        } else {
          $(this).addClass('active')
          const heightContent = this.querySelector('.accordion__content').offsetHeight ;
          this.querySelector('.accordion__description').style.height = 18 +  heightContent + 'px';
        }
      })
    }

    //new slick();


    function postsCarousel() {
      let checkWidth = $(window).width();
      let owlPost = $('.mobileSlider');

      owlPost.each(function(i , elem) {
        if (checkWidth > 767) {

          if (typeof $(elem).data('owl.carousel') != 'undefined')
          {
            $(elem).data('owl.carousel').destroy();
          }
          $(elem).removeClass('owl-carousel');
        }
        else if (checkWidth < 768) {
          $(elem).addClass('owl-carousel');
          let dataNext = atob($(elem).data('next'));
          let dataPrev = atob($(elem).data('prev'));
          $(elem).owlCarousel({
            loop:true,
            responsiveClass:true,
            items:1,
            navText: ['<span class="icon">'+ dataPrev + '</span>', '<span class="icon">'+ dataNext + '</span>'],
            rewindNav : true,
            nav: true,
            dots: false,
          });
        }
    })}

    postsCarousel();$(window).resize(postsCarousel);



    function carouselContact() {
      const conactCarousel = document.getElementsByClassName('contact-carousel');

      if(conactCarousel.length > 0) {
        $(conactCarousel).addClass('owl-carousel');
        let dataNext = atob($(conactCarousel).data('next'));
        let dataPrev = atob($(conactCarousel).data('prev'));
        $(conactCarousel).owlCarousel({
          loop:true,
          responsiveClass:true,
          items:1,
          navText: ['<span class="icon">'+ dataPrev + '</span>', '<span class="icon">'+ dataNext + '</span>'],
          rewindNav : true,
          nav: true,
          dots: true,
        });
      }


    }

    carouselContact();

  },
  init() {

    $('html').addClass('js-active');

    //Add globals to window
    window.env = env;

    // JavaScript to be fired on all pages
    this.breakpoint = new Breakpoint();
    window.bp = this.breakpoint;
    // JavaScript to be fired on all pages
    // $( '#supermarquee' ).supermarquee();
    $('.js-block-link').on('click', '.js-link-href, a', (e) => {
      e.stopPropagation();
    });

    $('.js-block-link').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      let $link = $(e.currentTarget).find('a.js-link-href');

      if($link.length > 0) {
        $link[0].click();
      }
    });


    $('.animation_text').each((index, elem)=>{
      $(elem).marquee({
        duration: 20000,
        duplicated: true,
    });
    let innerText = $(elem).find('.js-marquee');
    if(innerText) $(elem).height($(innerText).height());
    

  });
   

   $('.ancre').on('click', '.js-link-href, a', (e) => {
      e.stopPropagation();
    }); 

    $('.anchor-link').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
  
      let val = $(this).attr('href');
      if(val != 0 &&  val.match('^#')) {
        let $scrollTo = $(val);
        if($scrollTo.length === 0) return false;
        let offset = parseInt($('.header').outerHeight());
        $('html, body').animate({
          scrollTop: $scrollTo.offset().top - (offset + parseInt($($scrollTo).css('padding-top'))),
      }, 2000);
        
      } else {
        $(location).attr('href', val)
      }
    });



    this.components = new Components();


    




  },
};
