import Player from '@vimeo/player';

class VimeoApi {
    constructor (params) {
        // Init variables
        this.$cache       = {};
        this.$cache.main  = params.target;

        this.isLoop = this.$cache.main.data('loop') ? true : false;
        this.hasTitle = this.$cache.main.data('title') ? true : false;
		this.hasLine = this.$cache.main.data('line') ? true : false;
		this.hasPortrait = this.$cache.main.data('portrait') ? true : false;
				
        this.isPlaying = false;
		this.player  = false;

        // Trigger init functions
        this.initCache();
        this.initEventConst();
        this.init();
    }

    initCache() {
        this.$cache.html = $('html');
        this.$cache.iframe = this.$cache.main.find('iframe');
    }

    init() {
		this.player = new Player(this.$cache.iframe, {maxwidth : 'none'});
        this.registerEvents();
    }

    initEventConst() {
		this.onLoadEvent = () => this.onLoad();
        this.onPlayEvent = () => this.onPlay();
        this.onPauseEvent = () => this.onPause();
		this.onEndedEvent = () => this.onEnded();
    }

    registerEvents() {
		this.player.on('loaded', this.onLoadEvent);
        this.player.on('play', this.onPlayEvent);
        this.player.on('pause', this.onPauseEvent);
		this.player.on('ended', this.onEndedEvent);
    }

    play() {
        this.player.play();
    }

    pause () {
        this.player.pause();
    }

    stop() {
        this.$cache.videoO.pause();
        this.$cache.videoO.currentTime = 0;
    }

    onPlay() {
        this.isPlaying = true;
    }

    onPause() {
        this.isPlaying = false;
    }
	
	onEnded() {
		this.isPlaying = false;
		this.$cache.main.trigger('ended');
	}
	
	onLoad() {
		this.$cache.main.trigger('ready');
	}
}
export default VimeoApi;
