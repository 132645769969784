/* stylelint-disable */
//import { gsap } from 'gsap';
class BlockMap {

    constructor (params) {
        // Init variables
        this.$cache       = {};
        this.$cache.main  = params.target;
    
    
        this.initCache();
        this.initMap();
       // this.initEventsConst();
       // this.registerEvents();
    
    }


    initCache () {

        this.$cache.html = $('html');
        this.$cache.body = this.$cache.html.find('body');
      

    }

      initMap() {
        /* stylelint-disable-line */
        /*(g=>{var h,a,k,p='The Google Maps JavaScript API',c='google',l='importLibrary',q='__ib__',m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement('script'));e.set('libraries',[...r]+'');for(k in g)e.set(k.replace(/[A-Z]/g,t=>'_'+t[0].toLowerCase()),g[k]);e.set('callback',c+'.maps.'+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+' could not load.'));a.nonce=m.querySelector('script[nonce]')?.nonce||'';m.head.append(a)}));d[l]?console.warn(p+' only loads once. Ignoring:',g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
            key: 'AIzaSyCkufOrL2nfrwlGX4A1evKM_NHldYxTqd4',
            // Add other bootstrap parameters as needed, using camel case.
            // Use the 'v' parameter to indicate the version to load (alpha, beta, weekly, etc.)
          });

        let map;

        // The location of Uluru
        const position = { lat: 45.573850051679074, lng: -73.60997103535736 }; 
        // Request needed libraries.
        //@ts-ignore
        const { Map } = await google.maps.importLibrary('maps');
        //const { AdvancedMarkerView } = await google.maps.importLibrary('marker');
        let stylesArray = [
              {
                'featureType': 'landscape',
                'stylers': [
                  {
                    'color': '#f8f5f3',
                  },
                ],
              },
              {
                'featureType': 'road',
                'stylers': [
                  {
                    'color': '#e9caad',
                  },
                ],
              },
              {
                'featureType': 'road.highway',
                'stylers': [
                  {
                    'color': '#e9caad',
                  },
                ],
              },
              {
                'featureType': 'water',
                'stylers': [
                  {
                    'color': '#bed5df',
                  },
                ],
              },
        ]
    
        // The map, centered at Uluru
        map = new Map(document.getElementById('map'), {
          zoom: 12,
          streetViewControl: false,
          center: position,
          disableDefaultUI: true,
          styles: stylesArray,
        });
    
        */
        // The marker, positioned at Uluru
        
      }






}

export default BlockMap;