import { gsap } from 'gsap';

class VideoPlayer {

	constructor (params) {
		// Init variables
		this.$cache       = {};
		this.$cache.main  = params.target;

		this.youtubeObj = false;
		this.htmlObj = false;

		// Trigger init functions
		this.initCache();

		if(this.$cache.vimeo.length > 0) {
			// Wait for vimeo API to be ready
			this.$cache.vimeo.one('ready', () => this.initVideoPlayer());
		} else if(this.$cache.youtube.length > 0) {
			// Wait for youtube API to be ready
			this.$cache.youtube.one('ready', () => this.initVideoPlayer());
		} else {
			if(window.env.os.ios) {
				this.interval = setInterval(() => {
					let cond = false;

					this.htmlObj = this.$cache.videohtml.data('comp');
					if(this.htmlObj !== undefined) cond = true;

					if(cond) {
						clearInterval(this.interval);
						this.initVideoPlayer();
					}
				}, 50);
			} else {
				this.$cache.videohtml.one('loaded', () => this.initVideoPlayer());
			}
		}
	}

	initCache() {
		this.$cache.vimeo = this.$cache.main.find('.pn-vimeo');
		this.$cache.youtube = this.$cache.main.find('.pn-youtube');
		this.$cache.videohtml = this.$cache.main.find('.pn-video');

		this.$cache.overlayWrapper = this.$cache.main.find('.overlay-wrapper');

		this.$cache.play = this.$cache.overlayWrapper.find('.play-wrapper');
		this.$cache.playInner = this.$cache.play.find('.play-inner');
	}

	registerEvents() {
		this.$cache.play.on('click', () => {
			let callback = false;

			if(this.vimeoObj) {
				callback = () => this.vimeoObj.play();
				this.hideCover(callback);
			}

			if(this.youtubeObj) {
				callback = () => this.youtubeObj.playVideo();
				this.hideCover(callback);
			}

			if(this.htmlObj) {
				this.htmlObj.$cache.videoO.play();
				this.hideCover();
			}
		});

		if(this.$cache.vimeo.length > 0) {
			this.$cache.vimeo.on('ended', () => {
				this.showCover();
			});
		}

		if(this.$cache.youtube.length > 0) {
			this.$cache.youtube.on('ended', () => {
				this.showCover();
			});
		}

		if(this.$cache.videohtml.length > 0) {
			if(!this.htmlObj.isLoop) {
				this.htmlObj.$cache.video.on('ended', () => {
					this.showCover();
				});
			}
		}
	}

  initVideoPlayer() {
		if(this.$cache.vimeo.length > 0) {
			this.vimeoObj = this.$cache.vimeo.data('comp');
			this.$cache.main.trigger('vimeoReady');
		} else if(this.$cache.youtube.length > 0) {
			this.youtubeObj = this.$cache.youtube.data('comp');
			this.$cache.main.trigger('ytReady');
		} else {
			this.htmlObj = this.$cache.videohtml.data('comp');
			this.$cache.main.trigger('htmlReady');
		}

		this.$cache.main.trigger('playerReady');

		$(window).trigger('resize');

		// Bind events
		this.registerEvents();
  }

  /**
	 * Show Cover
	 */
	showCover(callback) {
		var tl = new gsap.timeline({paused: true, onStart: () => {
			this.$cache.overlayWrapper.show();
			$(window).trigger('resize');
		}, onComplete: () => {
			if(callback) callback();
		}});

		tl.to(this.$cache.overlayWrapper, {opacity: 1, duration: 0.6, ease: 'cubic.out'}, 0.1);
		tl.play();
	}

	/**
	* Hide Cover
	*/
	hideCover(callback) {
		var tl = new gsap.timeline({paused: true, onStart: () => {
			this.$cache.playInner.addClass('no-transition');
			if(callback) callback();
		}, onComplete: () => {
			this.$cache.overlayWrapper.hide();
			this.$cache.playInner.removeClass('no-transition');
			gsap.set(this.$cache.playInner, {opacity: 1});
		}});

		tl.to(this.$cache.overlayWrapper, {opacity: 0, duration: 0.8, force3D: true, ease: 'cubic.inOut'}, 0);
		tl.to(this.$cache.playInner, {opacity: 0, duration: 0.6, ease: 'expo.inOut'}, 0)
		tl.play();
	}
}

export default VideoPlayer;
